import { defineComponent } from "vue"

export default defineComponent({
    data() {
        return {
            refreshing: false,
            registration: [] as any[any],
            updateExists: false,
        }
    },

    created() {
        // Listen for our custom event from the SW update
        document.addEventListener('swUpdate', this.updateAvailable, { once: true })

        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.refreshApp, { once: true })

        // Prevent multiple refreshes
        if(navigator.serviceWorker){
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event:any) {
            this.registration = event.detail
            this.updateExists = true
        },

        // Called when the user accepts the update
        refreshApp() {
            //this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    }
})
