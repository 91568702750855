<template>
  <!-- progress bar -->
  <div class="progressBar w-full h-1 fixed top-0 left-0 z-30">
    <div class="bg-primary-700 h-full" :class="progress > 0 ? 'transition-all' : 'transition-none'"
      :style="'width: ' + progress + '%'"></div>
  </div>

  <!-- Nav Bar -->
  <div class="navBar w-full h-16 md:h-20 bg-white shadow-md fixed top-0 left-0 z-20" v-show="$route.path != '/login'">
    <div class="container h-full flex items-center justify-between">
      <!-- logo -->
      <div class="logo flex-none">
        <router-link to="/">
          <img src="/img/logo.svg" alt="logo" class="h-8 md:h-14">
        </router-link>
      </div>

      <!-- menu-->
      <nav class="menu hidden h-full lg:block">
        <ul class="flex items-center h-full gap-8">
          <li v-for="option, index in menuOptions" :key="index"
            :class="$route.path == option.link ? 'text-primary-900' : ''" class="h-full flex items-center">
            <router-link :to="option.link" v-if="option.suboptions.length == 0">
              {{ option.name }}
            </router-link>
            <!-- dropdown -->
            <Dropdown v-else :label="option.name" :options="option.suboptions" mode="floating"></Dropdown>
          </li>
        </ul>
      </nav>

      <!-- divider -->
      <div class="divider h-11 border-l hidden lg:block"></div>

      <!-- login / user -->
      <router-link :to="$store.state.token ? '/perfil' : '/login'" class="hidden lg:block">
        <div class="user flex gap-4 items-center justify-end">
          <div class="profile" v-if="$store.state.token">
            <p class="userName font-bold">Hola, {{ $store.state.user.first_name }}</p>
            <p class="text-xs text-right">Mi perfil</p>
          </div>
          <p class="userName font-bold" v-else>Ingresar</p>
          <div class="userImg w-10 aspect-square rounded-full">
            <img src="/img/icons/user.svg" class="w-full h-full" alt="login">
          </div>
        </div>
      </router-link>

      <!-- mobile menu -->
      <div class="sideBarButton w-6 h-6 lg:hidden" @click="toggleSideBar()">
        <IconComponent url="/img/icons/menu.svg" color="bg-gray-500"></IconComponent>
      </div>
    </div>
  </div>

  <!-- SideBar -->
  <SideBar class="p-4" :isOpen="sideBarOpen">
    <!-- Header -->
    <div class="sideBarHeader pb-4 flex items-center justify-between border-b">
      <!-- login / user -->
      <router-link :to="$store.state.token ? '/perfil' : '/login'" @click="toggleSideBar()">
        <div class="user flex gap-4 items-center justify-end">
          <div class="userImg w-10 aspect-square rounded-full">
            <img src="/img/icons/user.svg" class="w-full h-full" alt="login">
          </div>
          <div class="profile" v-if="$store.state.token">
            <p class="userName font-bold">Hola, {{ $store.state.user.first_name }}</p>
            <p class="text-xs">Mi perfil</p>
          </div>
          <p class="userName font-bold" v-else>Ingresar</p>
        </div>
      </router-link>

      <!-- close icon -->
      <div class="closeIcon w-6 h-6 flex-none bg-black" @click="toggleSideBar()"
        style="mask-image:url('/img/icons/x.svg');mask-position:center;mask-size:cover">
      </div>
    </div>
    <!-- Body -->
    <div class="sideBarBody flex flex-col">
      <!-- home -->
      <div class="menuOption py-4 flex gap-2 items-center border-b">
        <router-link to="/" @click="toggleSideBar()">
          <p>Inicio</p>
        </router-link>
      </div>

      <!-- dynamics options -->
      <div class="menuOption" v-for="menuOption, index in menuOptions" :key="index">
        <Dropdown class="sideBarOption py-4 border-b" :label="menuOption.name" :options="menuOption.suboptions"
          @optionClicked="toggleSideBar()" v-if="menuOption.suboptions && menuOption.suboptions.length > 0">
        </Dropdown>
        <router-link :to="menuOption.link" @click="toggleSideBar()" v-else>
          <div class="sideBarOption py-4 flex gap-2 items-center border-b">
            <p class="text-gray-900 capitalize">
              {{ menuOption.name }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </SideBar>

  <!-- main -->
  <main class="text-gray-700 min-h-screen" :class="$route.path != '/login' ? 'pt-16 md:pt-20' : 'pt-0'">
    <router-view v-slot="{ Component }">
      <!--transition name="fade" mode="out-in"-->
      <keep-alive :key="$route.fullPath">
        <component :is="Component" />
      </keep-alive>
      <!--/transition-->
    </router-view>

    <!-- on update -->
    <div class="update fixed top-0 left-0 w-full h-screen bg-white z-30 flex items-center justify-center"
      v-if="updateExists">
      <div class="updateContent space-y-4">
        <!-- spinner -->
        <div role="status">
          <svg aria-hidden="true" class="mx-auto w-16 h-16 text-gray-100 animate-spin fill-primary-700"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
        </div>
        <!-- message -->
        <div class="text">
          <p class="text-xl text-center">Actualizando</p>
          <p class="text-sm">Aguarde un momento, por favor.</p>
        </div>
      </div>
    </div>
  </main>

  <FooterComponent v-show="$route.path != '/login'" />
</template>

<script lang="ts">
//update
import update from './update'

//utilities
import utilities from './utilities'

//custom components
import IconComponent from './components/IconComponent.vue';
import SideBar from './components/SideBar.vue';
import Dropdown from './components/Dropdown.vue';
import FooterComponent from './components/FooterComponent.vue';
import { defineComponent } from 'vue';

//this component
export default defineComponent({
  name: 'AppView',
  components: {
    IconComponent,
    SideBar,
    Dropdown,
    FooterComponent
  },
  mixins: [update],
  data() {
    return {
      refreshing: false,
      registration: [] as any[any],
      updateExists: false,
      progress: 0,
      sideBarOpen: false,
      menuOptions: [
        {
          name: 'Embajadoras',
          link: "/embajadoras",
          suboptions: []
        },
        {
          name: 'Desarrollo Profesional',
          link: "",
          suboptions: [
            {
              name: 'Cursos',
              link: '/cursos'
            }
          ]
        },
        {
          name: 'Inspiración',
          link: "",
          suboptions: [
            {
              name: 'Historia de mujeres',
              link: '/inspiracion/historia-de-mujeres'
            },
            {
              name: 'Un dia en la vida de',
              link: '/inspiracion/un-dia-en-la-vida-de'
            },
            {
              name: 'Entrevistas',
              link: '/inspiracion/entrevistas'
            },
            {
              name: 'Podcasts',
              link: '/inspiracion/podcasts'
            }
          ]
        },
        {
          name: 'Eventos',
          link: "/eventos",
          suboptions: []
        },
        {
          name: 'Contactanos',
          link: "/contacto",
          suboptions: []
        }
      ]
    }
  },
  async created() {
    //check if on mobile
    this.isMobile()
    window.addEventListener('resize', this.isMobile);

    //get config
    const config = await utilities.getItems('/items/config/1?fields=*,main_banner.data,main_banner.type')
    this.$store.commit('setConfig', config)

    //disable entepreneurship and internships
    if ((!this.$store.state.config.entrepreneurships && this.$route.path == '/emprendimientos') || (!this.$store.state.config.internships && this.$route.path.includes('/pasantias'))) {
      this.$router.push('/404')
    }

    if (this.$store.state.config.entrepreneurships) {
      this.menuOptions.splice(3, 0, {
        name: 'Emprendimientos',
        link: "/emprendimientos",
        suboptions: []
      })
    }

    if (this.$store.state.config.internships) {
      this.menuOptions[1].suboptions.push({
        name: 'Pasantías',
        link: '/pasantias'
      })
    }
  },
  mounted() {
    // detetect oute change for progress bar
    this.$router.beforeEach((to, from, next) => {
      this.progress = 75
      next()
    });

    this.$router.afterEach(() => {
      this.progress = 100
      setTimeout(() => {
        this.progress = 0
      }, 300);
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.isMobile);
  },
  methods: {
    toggleSideBar() {
      this.sideBarOpen = !this.sideBarOpen
    },
    isMobile() {
      if (screen.width <= 760) {
        this.$store.state.mobile = true;
      }
      else {
        this.$store.state.mobile = false;
      }
    }
  }
})
</script>

<style>
#app {
  font-family: 'Nunito Sans', sans-serif;
}

/*-- Transition --*/
.fade-enter-active {
  animation: enter .5s linear;
}

.fade-leave-active {
  opacity: 0;
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@keyframes leave {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}
</style>