<template>
    <div class="CardBig aspect-[3/5] md:aspect-[5/2] bg-gray-100 rounded-xl overflow-hidden flex flex-col md:flex-row items-center gap-4"
        :class="Object.keys(data).length > 0 ? '' : 'animate-pulse'">

        <!-- content -->
        <div class="content w-full h-full px-4 py-8 md:px-16 text-gray-900 flex flex-col justify-center"
            v-if="Object.keys(data).length > 0">
            <!-- Interview content -->
            <div class="interviewContent space-y-6" v-if="type == 'interview'">
                <h2 class="text-2xl md:text-3xl font-semibold line-clamp-2">{{ data.title }}</h2>
                <div class="description text-lg line-clamp-3" v-html="data.excerpt"></div>
                <!--div class="interviewInfo">
                    <p class="font-semibold">-- Nombre entrevistada</p>
                    <p class="text-gray-500">Buenos Aires, 20/09/2023</p>
                </div-->
            </div>
            <!-- Event content -->
            <div class="eventContent space-y-6" v-else-if="type == 'event'">
                <!--title-->
                <h2 class="text-2xl md:text-3xl font-semibold line-clamp-2">{{ data.name }}</h2>
                <!--description-->
                <div class="description text-lg line-clamp-3" v-html="data.description"></div>
                <!--date-->
                <div class="date flex items-center gap-2">
                    <IconComponent color="bg-primary-900" width="w-5" url="/img/icons/calendar-icon.svg">
                    </IconComponent>
                    <p v-if="data.date_to">Desde el {{ utilities.getFormatedDate(data.date_from) }} hasta el {{ utilities.getFormatedDate(data.date_to) }}</p>
                    <p v-else>{{ utilities.getFormatedDate(data.date_from) }}</p>
                </div>
                <!--time-->
                <div class="time flex items-center gap-2">
                    <IconComponent color="bg-primary-900" width="w-5" url="/img/icons/clock-icon.svg">
                    </IconComponent>
                    <p v-if="data.time_to">De {{ data.time_from }} a {{ data.time_to }}</p>
                    <p v-else>Inicia {{ data.time_from }}</p>
                </div>
                <!--location-->
                <div class="location flex items-center gap-2">
                    <IconComponent color="bg-primary-900" width="w-5" url="/img/icons/location-icon.svg" class="flex-none">
                    </IconComponent>
                    <p>{{ data.location }}</p>
                </div>
            </div>
        </div>

        <!-- skeleton -->
        <div class="skeleton w-full h-full" v-else>
            <!--Interview skeleton-->
            <div class="text w-full h-full px-4 py-8 md:px-16 text-gray-900 flex flex-col justify-center gap-6">
                <!--Title-->
                <div class="title space-y-2">
                    <div class="titleSckeleton w-full bg-gray-300 h-6 rounded-md"></div>
                    <div class="titleSckeleton w-1/2 bg-gray-300 h-6 rounded-md"></div>
                </div>
                <div class="text space-y-2">
                    <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                    <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                    <div class="textSkeleton w-1/2 h-5 bg-gray-300 rounded-md"></div>
                </div>
                <div class="name space-y-2">
                    <div class="textSkeleton w-1/2 h-4 bg-gray-300 rounded-md"></div>
                    <div class="textSkeleton w-1/2 h-4 bg-gray-300 rounded-md"></div>
                </div>
            </div>
        </div>

        <!-- image -->
        <div class="img w-full md:w-auto md:h-full bg-gray-300 flex-none"
            :class="type == 'interview' ? 'aspect-square' : 'aspect-square md:aspect-[3/2]'">
            <img :src="img" alt="Portada" class="w-full h-full object-cover" v-if="img">
        </div>
    </div>
</template>

<script lang="ts">
//Components
import IconComponent from '@/components/IconComponent.vue';

//utilities
import utilities from '../utilities'

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BigCardComponent',
    components: {
        IconComponent
    },
    computed: {
        utilities: () => {
            return utilities
        }
    },
    props: {
        type: String,
        data: {
            type: Object,
            default: () => ({})
        },
        img: String
    }
})
</script>