import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LogInView.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/embajadoras',
    name: 'embajadoras',
    component: () => import('../views/AmbassadorsView.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/eventos/:id',
    name: 'eventos single',
    component: () => import('../views/singles/EventSingle.vue')
  },
  {
    path: '/emprendimientos',
    name: 'emprendimientos',
    component: () => import('../views/Entrepreneurship.vue')
  },
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import('../views/Courses.vue')
  },
  {
    path: '/cursos/:courseSlug/:moduleSlug?',
    name: 'cursos single',
    component: () => import('../views/singles/VideoSingle.vue')
  },
  {
    path: '/pasantias',
    name: 'pasantias',
    component: () => import('../views/Interships.vue')
  },
  {
    path: '/pasantias/:id',
    name: 'pasantias single',
    component: () => import('../views/singles/IntershipSingle.vue')
  },
  {
    path: '/inspiracion/:sectionSlug',
    name: 'inspiracion',
    component: () => import('../views/Inspiration.vue')
  },
  {
    path: '/inspiracion/:sectionSlug/:slug',
    name: 'inspiracion single',
    component: () => import('../views/singles/ArticleSingle.vue')
  },
  {
    path: '/inspiracion/un-dia-en-la-vida-de/:slug',
    name: 'un dia en la vida de single',
    component: () => import('../views/singles/VideoSingle.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../views/Legal.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../views/Profile.vue'),
    children: [
      {
        path: '',
        redirect: '/perfil/mis-datos',
      },
      {
        path: 'mis-datos',
        component: () => import('../views/profile/UserData.vue'),
      },
      {
        path: 'sobre-mi',
        component: () => import('../views/profile/AboutMe.vue'),
      },
      {
        path: 'seguridad',
        component: () => import('../views/profile/Security.vue'),
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function () {
      return import('../views/404.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        setTimeout(() => {
          return resolve(savedPosition);
        }, 0)
      } else {
        return resolve({ top: 0 });
      }
    })
  }
})

export default router
