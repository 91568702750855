//axios
import axios from 'axios'
//router
import router from './router';
//store
import store from './store';

// set apiUrl
const apiUrl = 'https://admin.mec.adro.studio/mec'

const utils = {
  getItems: async function (request: string, token = '', returnTo = '/') {
    const endpoint = (token != '')
      ? apiUrl + request + '&access_token=' + token
      : apiUrl + request

    try {
      const response = await axios.get(endpoint);

      if (response.status == 200) {
        if(response.data.meta) {
          return response.data
        }
        else {
          return response.data.data
        }
      }
    }
    catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        router.push('/login?to=' + returnTo);
      }
    }
  },
  auth: async function (email: string, password: string, onError: any, returnTo: string) {
    const endpoint = apiUrl + '/auth/authenticate'

    const response = await axios.post(endpoint, { 'email': email, 'password': password })
      .catch(error => {
        const errorCode = error.response.data.error.code

        if (errorCode == 100) {
          onError('El mail y/o la contraseña son incorrectos')
        } else if (errorCode == 103) {
          onError('Cuenta inactiva')
        } else {
          onError('Hubo un error. Intente nuevamente.')
        }
      }) as any

    if (response && response.status == 200) {
      //set user data
      store.commit('setUserData', response.data.data.user)
      store.commit('setToken', response.data.data.token)
      //store.state.token = response.data.data.token
      if (returnTo) {
        router.push(returnTo)
      }else{
        router.push("/")
      }
    }
  },
  checkForm: function (form: FormData) {
    let empyInputs = true

    Array.from(form).forEach((input: any[any]) => {
      if (input.required && input.value === '' && empyInputs) {
        empyInputs = false
      }
    })

    return empyInputs
  },
  createAccount: async function (firstName: string, lastName: string, email: string, password: string, onError: any) {
    const endpoint = apiUrl + '/items/users'
    const response = await axios.post(endpoint,
      {
        "status": "active",
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "password": password
      }
    ).catch(error => {
      onError(error.response.data.error.message)
      throw error.response.data.error.message
    })

    return 'ok'
  },
  resetPassword: async function (email: string) {
    const endpoint = apiUrl + '/auth/password/request'
    const response = await axios.post(endpoint,
      {
        "email": email,
        "reset_url": "https://mydomain/passwordreset"
      }
    )

    if (response.status == 200) {
      return 'sent'
    } else {
      return 'error'
    }
  },
  setPassword: async function (password: string, token: string) {
    const endpoint = apiUrl + '/auth/password/reset'
    const response = await axios.post(endpoint,
      {
        "token": token,
        "password": password
      }
    )

    if (response.status == 200) {
      return 'ok'
    } else {
      return 'error'
    }
  },
  getYouTubeCover(url:string, size = 'hq') {
    const videoId = this.getYouTubeId(url)
    if(size == 'hq') {
      return 'https://i.ytimg.com/vi/' + videoId + '/hqdefault.jpg'
    }
    else {
      return 'https://i.ytimg.com/vi/' + videoId + '/maxresdefault.jpg'
    }
  },
  getYouTubeId(url: string) {
    //clean url
    url = url.replace(/"/g, "");

    //define id
    let id: string;

    //get domain
    try {
      const link = new URL(url);
      const domain = link.hostname;

      //get id
      if (domain == "youtu.be") {
        id = link.pathname.replace("/", "");
      } else if (link.searchParams.get("v")) {
        id = link.searchParams.get("v") as string;
      } else {
        id = url.split("/")[4];
      }

      return id;
    }
    catch (_) {
      return 0
    }
  },
  getFormatedDate: function (dateString: string) {
    const date = new Date(dateString)
    const formatedDate = date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
    return formatedDate
  },
}

export default utils

